import { useState } from "react";
import { Upload } from "lucide-react";

interface Person {
  name: string;
  email: string;
  recruitment: string;
  exchange: string;
}

type LCData = Record<string, Person[]>;

const dummyLCData: LCData = {
  "LUMS": [
    { name: "Ali Khan", email: "ali.khan@example.com", recruitment: "Yes", exchange: "No" },
    { name: "Sara Ahmed", email: "sara.ahmed@example.com", recruitment: "No", exchange: "Yes" }
  ],
  "IBA": [
    { name: "Usman Raza", email: "usman.raza@example.com", recruitment: "Yes", exchange: "Yes" },
    { name: "Zainab Malik", email: "zainab.malik@example.com", recruitment: "No", exchange: "No" }
  ],
  "NUST": [],
  "Islamabad": [],
  "GIKI": [],
  "Multan": [],
  "Hyderabad": [],
  "Lahore": [],
  "Karachi": [],
  "Karachi South": [],
  "Faisalabad": []
};

const LCs = Object.keys(dummyLCData);

export default function ELDConversion() {
  const [file, setFile] = useState<File | null>(null);
  const [selectedLC, setSelectedLC] = useState<string>("National");
  const selectedData: Person[] = selectedLC === "National" ? Object.values(dummyLCData).flat() : dummyLCData[selectedLC];
  
  const totalSignups = selectedData.length;
  const recruitmentCount = selectedData.filter((d) => d.recruitment === "Yes").length;
  const exchangeCount = selectedData.filter((d) => d.exchange === "Yes").length;
  const conversionRate = totalSignups ? ((recruitmentCount + exchangeCount) / totalSignups) * 100 : 0;

  return (
    <div className="flex flex-col items-center p-6 space-y-6">
      <div className="w-full max-w-2xl p-6 shadow-lg rounded-2xl border bg-white">
        <div className="flex flex-col items-center space-y-4">
          <h2 className="text-2xl font-semibold">ELD Conversion Tracker</h2>
          <div className="flex flex-wrap gap-2">
            {LCs.map(lc => (
              <button key={lc} onClick={() => setSelectedLC(lc)} className={`px-4 py-2 rounded-lg ${selectedLC === lc ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}>
                {lc}
              </button>
            ))}
            <button onClick={() => setSelectedLC("National")} className={`px-4 py-2 rounded-lg ${selectedLC === "National" ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}>
              National
            </button>
          </div>
          <div className="flex w-full space-x-4">
            <input type="file" onChange={(e) => {
              if (e.target.files) {
                setFile(e.target.files[0]);
              }
            }} className="hidden" id="csvUpload" />
            <label htmlFor="csvUpload" className="flex items-center justify-center w-full px-4 py-2 bg-blue-500 text-white rounded-lg cursor-pointer hover:bg-blue-600">
              <Upload className="mr-2" /> Upload CSV
            </label>
          </div>
          {file && <p className="text-sm text-gray-500">Uploaded: {file.name}</p>}
          <div className="w-full flex justify-between text-lg font-medium">
            <span>Total Signups: {totalSignups}</span>
            <span>Recruitment: {recruitmentCount}</span>
            <span>Exchange: {exchangeCount}</span>
            <span>Conversion: {conversionRate.toFixed(2)}%</span>
          </div>
        </div>
      </div>
      <div className="w-full max-w-4xl p-6 shadow-lg rounded-2xl border bg-white">
        <h3 className="text-xl font-semibold mb-4">{selectedLC} Signups & Conversion Data</h3>
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-300 px-4 py-2">Name</th>
              <th className="border border-gray-300 px-4 py-2">Email</th>
              <th className="border border-gray-300 px-4 py-2">Recruitment</th>
              <th className="border border-gray-300 px-4 py-2">Exchange</th>
            </tr>
          </thead>
          <tbody>
            {selectedData.map((person, index) => (
              <tr key={index} className="odd:bg-white even:bg-gray-50">
                <td className="border border-gray-300 px-4 py-2">{person.name}</td>
                <td className="border border-gray-300 px-4 py-2">{person.email}</td>
                <td className="border border-gray-300 px-4 py-2">{person.recruitment}</td>
                <td className="border border-gray-300 px-4 py-2">{person.exchange}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
