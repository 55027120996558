import React, { useState } from "react";

interface FormData {
  firstName: string;
  lastName: string;
  gender: string;
  contactNumber: string;
  personalEmail: string;
  localCommitee: string;
  currentPosition: string;
  educated: string;
  standardsFullfilled: string;
  leadershipOpinion: string;
  workAppreciated: string;
  recommending: string;
  overworked: string;
  expectationsMet: string;
  utilisedMore: string;
  overallRating: string;
  suggestions: string;
}

const MemberSatisfaction: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    lastName: "",
    gender: "",
    contactNumber: "",
    personalEmail: "",
    localCommitee: "",
    currentPosition: "",
    educated: "",
    standardsFullfilled: "",
    leadershipOpinion: "",
    workAppreciated: "",
    recommending: "",
    overworked: "",
    expectationsMet: "",
    utilisedMore: "",
    overallRating: "5",
    suggestions: "",
  });
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [contactNumberError, setContactNumberError] = useState<string | null>(
    null
  );
  const [showOtherInput, setShowOtherInput] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string | null>(null); // New state for email error message

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;

    // Check Contact Number format
    if (name === "contactNumber") {
      const contactPattern = /^03[0-9]{9}$/;
      if (!contactPattern.test(value)) {
        setContactNumberError(
          "Contact number must be in the format 03000000000"
        );
      } else {
        setContactNumberError(null);
      }
    }

    setFormData({ ...formData, [name]: value });
  };

  const steps = [
    {
      title: "",
      fields: (
        <>
          <div className="text-center">
            <h2 className="text-3xl font-bold text-blue-600 mb-4">
              AIESEC in Pakistan Member Happiness Survey
            </h2>
            <br />
            <p className="text-lg mb-6 text-gray-700">
              We value your experience and feedback! Please take a moment to
              share your thoughts on your membership journey. Your insights help
              us improve and create a better environment for everyone. Let us
              know what’s working well and where we can enhance your experience.
              Your responses will remain confidential and will be used to make
              meaningful improvements. Thank you for your time!
            </p>
          </div>
          <div className="flex justify-center">
            <img
              src="./happiness2.png"
              alt="Banner"
              className="w-full h-auto rounded-lg max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg"
            />
          </div>
        </>
      ),
    },
    {
      title: "Personal Information",
      fields: (
        <>
          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">First Name:</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500`}
            />
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">Last Name:</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500`}
            />
          </div>
        </>
      ),
    },
    {
      title: "Details",
      fields: (
        <>
          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">Contact Number:</label>
            <input
              type="text"
              name="contactNumber"
              value={formData.contactNumber}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500`}
            />
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">Personal Email:</label>
            <input
              type="email"
              name="personalEmail"
              value={formData.personalEmail}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 `}
            />
          </div>
          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">Current Position:</label>
            <input
              type="text"
              name="currentPosition"
              value={formData.currentPosition}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 `}
            />
          </div>
        </>
      ),
    },
    {
      title: "Form Questions",
      fields: (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">
              Were you educated about Member Standards?
            </label>
            <select
              name="educated"
              value={formData.educated}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 `}
              required
            >
              <option value="">Select Option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
              <option value="Maybe">Maybe</option>
            </select>
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">
              If yes, how many standards do you think your LC is fulfilling?
            </label>
            <input
              type="text"
              name="standardsFullfilled"
              value={formData.standardsFullfilled}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 `}
            />
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">
              Does the leadership take your opinions seriously?
            </label>
            <select
              name="degree"
              value={formData.leadershipOpinion}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 `}
            >
              <option value="">Select Option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
              <option value="Maybe">Maybe</option>
            </select>
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">
              Are you appreciated for your work?
            </label>
            <select
              name="workAppreciated"
              value={formData.workAppreciated}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 `}
            >
              <option value="">Select Option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
              <option value="Maybe">Maybe</option>
            </select>
          </div>
        </div>
      ),
    },
    {
      title: "Feedback",
      fields: (
        <>
          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">
              Would you recommend AIESEC to your friends or family?
            </label>
            <select
              name="recommending"
              value={formData.recommending}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 `}
            >
              <option value="">Select Option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
              <option value="Maybe">Maybe</option>
            </select>
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">
              Do you feel overworked or under utilised?
            </label>
            <input
              type="text"
              name="overworked"
              value={formData.overworked}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 `}
            />
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">
              Did the organization meet your expectations?
            </label>
            <select
              name="expectationsMet"
              value={formData.expectationsMet}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 `}
            >
              <option value="">Select Option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
              <option value="Maybe">Maybe</option>
            </select>
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">
              Do you feel you could have been utilised more?
            </label>
            <select
              name="utilisedMore"
              value={formData.utilisedMore}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 `}
            >
              <option value="">Select Option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
              <option value="Maybe">Maybe</option>
            </select>
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">
              Overall, how would you rate your experience with AIESEC?
            </label>
            <input
              type="range"
              name="overallRating"
              min="0"
              max="10"
              value={formData.overallRating}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 `}
            />
          </div>
        </>
      ),
    },
    {
      title: "Final Information",
      fields: (
        <>
          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">
              Do you have any suggestions for us to consider moving forward?
            </label>
            <textarea
              name="reasonForJoining"
              value={formData.suggestions}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 `}
              required
            />
          </div>
        </>
      ),
    },
    {
      title: "Form Submitted",
      fields: (
        <div className="text-center">
          <h3 className="text-2xl font-bold mb-4">Thank you!</h3>
          <p>Your form has been successfully submitted.</p>
          {isSubmitted && <p>Submitted</p>}
        </div>
      ),
    },
  ];

  //   const handleSubmit = async (e: React.FormEvent) => {
  //     e.preventDefault();

  //     try {
  //       await fetch(
  //         "https://script.google.com/macros/s/AKfycbwHVj6EjjYF742ETNpKXs9NZ_n7LX3a-DB5Bi_CXPXWFtz7XaiE--uc480M851ONmc3MA/exec",
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify(formData),
  //           mode: "no-cors", // Disable CORS to avoid cross-origin issues
  //         }
  //       );

  //       setIsSubmitted(true); // Update submission status
  //       setCurrentStep((prev) => prev + 1); // Move to the final step
  //     } catch (error) {
  //       console.error("Error submitting form:", error);
  //       alert("Failed to submit the form.");
  //     }
  //   };

  //   const nextStep = () => {
  //     if (validateStep()) {
  //       setCurrentStep((prev) => prev + 1);
  //     } else {
  //       return <p>Complete all the fields</p>;
  //     }
  //   };

  //   const prevStep = () => setCurrentStep((prev) => prev - 1);

  //   return (
  //     <div className="min-h-screen flex items-center justify-center relative overflow-hidden">
  //       {/* Left half - White background */}
  //       <div className="absolute left-0 top-0 w-1/2 h-full bg-neutral-100">
  //         <div className="fixed top-20 left-4"></div>
  //       </div>

  //       {/* Right half - Blue background */}
  //       <div className="absolute right-0 top-0 w-1/2 h-full bg-blue-500"></div>

  //       {/* Centered form */}
  //       <div className="relative z-10 max-w-2xl mx-auto p-8 bg-white rounded-xl shadow-md">
  //         <div className="flex flex-col items-center">
  //           <h2 className="text-4xl font-semibold text-center mb-4 font-serif">
  //             AIESEC in Pakistan
  //           </h2>
  //           {/* <h2 className="text-4xl font-semibold text-center mb-4 font-serif">
  //           Fall 24.25 Recruitment Form
  //           </h2> */}

  //           <img
  //             src="./banner.png"
  //             alt="Banner"
  //             className="w-full rounded-lg h-auto max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg mb-4"
  //           />
  //           {!isSubmitted && (
  //             <p className="pb-5">
  //               Make sure to fill every field or else your form will not be
  //               accepted
  //             </p>
  //           )}
  //         </div>

  //         <form onSubmit={handleSubmit}>
  //           <div className="mb-6">
  //             <h3 className="text-xl font-medium mb-4">
  //               {steps[currentStep].title}
  //             </h3>
  //             {steps[currentStep].fields}
  //           </div>
  //           <div className="flex justify-between items-center">
  //   {/* Previous Button */}
  //   {currentStep > 0 && (
  //     <button
  //       type="button"
  //       className="bg-gray-400 text-white py-2 px-4 rounded-lg hover:bg-gray-500 transition duration-200"
  //       onClick={prevStep}
  //     >
  //       Previous
  //     </button>
  //   )}

  //   {/* Center Logo */}
  //   <img src="./aieseclogo.png" alt="AIESEC" className="w-20 mx-auto" />

  //   {/* Next Button or Submit Button */}
  //   {currentStep < steps.length - 2 && (
  //     <button
  //       type="button"
  //       className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-200"
  //       onClick={nextStep}
  //     >
  //       Next
  //     </button>
  //   )}
  //   {currentStep === steps.length - 2 && (
  //     <button
  //       type="submit"
  //       className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition duration-200"
  //     >
  //       Submit
  //     </button>
  //   )}
  // </div>

  //         </form>
  //       </div>
  //     </div>
  //   );
  // };

  // export default MemberSatisfaction;

  const handleSubmit = async (e: React.FormEvent) => {};

  const nextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const prevStep = () => setCurrentStep((prev) => prev - 1);

  return (
    <div className="min-h-screen flex items-center justify-center relative overflow-hidden">
      {/* Left half - White background */}
      <div className="absolute left-0 top-0 w-1/2 h-full bg-nexusPurple">
        <div className="fixed top-20 left-4"></div>
      </div>

      {/* Right half - Blue background */}
      <div className="absolute right-0 top-0 w-1/2 h-full bg-nexusPurple"></div>

      {/* Centered form */}
      <div className="relative z-10 max-w-2xl mx-auto p-8 bg-white rounded-xl shadow-md">
        {loading ? (
          <div className="text-center">
            <h3 className="text-2xl font-bold mb-4">Submitting...</h3>
            <p>Please wait while we process your submission.</p>
            {/* You can also add a spinner or any other loading indicator here */}
          </div>
        ) : (
          <>
            <div className="flex flex-col items-center">
              <h2 className="text-4xl font-semibold text-center mb-4 font-serif">
                Member Happiness Survey
              </h2>
              <img
                src="./happiness.png"
                alt="Banner"
                className="w-full rounded-lg h-auto max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg mb-4"
              />
              {!isSubmitted && (
                <p className="pb-5">
                  Make sure to fill every field or else your form will not be
                  accepted
                </p>
              )}
            </div>

            <form onSubmit={handleSubmit}>
              <div className="mb-6">
                <h3 className="text-xl font-medium mb-4">
                  {steps[currentStep].title}
                </h3>
                {steps[currentStep].fields}

                {/* Display email error message */}
                {emailError && (
                  <div className="text-center mt-4">
                    <p className="text-red-500">{emailError}</p>
                  </div>
                )}
              </div>
              <div className="flex justify-between items-center">
                {currentStep > 0 && (
                  <button
                    type="button"
                    className="bg-gray-400 text-white py-2 px-4 rounded-lg hover:bg-gray-500 transition duration-200"
                    onClick={prevStep}
                  >
                    Previous
                  </button>
                )}

                {currentStep < steps.length - 2 && (
                  <button
                    type="button"
                    className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-200"
                    onClick={nextStep}
                  >
                    Next
                  </button>
                )}
                {currentStep === steps.length - 2 && (
                  <button
                    type="submit"
                    className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition duration-200"
                    disabled={loading} // Disable button while loading
                  >
                    Submit
                  </button>
                )}
              </div>
            </form>

            {/* Display success message if submitted */}
            {currentStep === steps.length - 1 && isSubmitted && (
              <div className="text-center mt-4">
                <p>Your form has been successfully submitted.</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MemberSatisfaction;
